import { AppBar, createStyles, makeStyles, Toolbar } from "@material-ui/core";
import { StaticImage } from "gatsby-plugin-image";
import { Button as GatsbyMuiButton } from "gatsby-theme-material-ui";
import React from "react";

const useStyles = makeStyles(theme =>
  createStyles({
    separator: {
      display: "flex",
      alignItems: "center",
      flexGrow: 1,
    },
  })
);

// const base_external = "https://studio.postreality.io";

export const Navigation: React.FC = () => {
  const styles = useStyles();
  return (
    <AppBar component="nav" position="sticky" variant="outlined">
      <Toolbar variant="dense">
        <div className={styles.separator}>
          <StaticImage
            src="../images/spearxr_logo_dark.svg"
            height={56}
            layout="fixed"
            alt="SpearXR"
          />
          {/* <Typography variant="h4">SpearXR</Typography> */}
        </div>
        <GatsbyMuiButton to="/" color="inherit">
          Home
        </GatsbyMuiButton>
        <GatsbyMuiButton
          to="#case-studies"
          color="inherit"
          style={{ textAlign: "center" }}
        >
          Case Studies
        </GatsbyMuiButton>
        <GatsbyMuiButton to="#our-services" color="inherit">
          Services
        </GatsbyMuiButton>
        {/* <Button
          href={`${base_external}/auth/login`}
          size="large"
          target="_blank"
          title="Sign in"
          color="inherit"
        >
          Sign in
        </Button>
        <Button
          href={`${base_external}/auth/signup`}
          size="large"
          target="_blank"
          title="Sign up"
          color="inherit"
        >
          Sign up
        </Button> */}
      </Toolbar>
    </AppBar>
  );
};
