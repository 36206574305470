import { createTheme } from "@material-ui/core";
import {
  deepOrange,
  // deepPurple,
  lightBlue,
  // yellow,
} from "@material-ui/core/colors";

const theme = createTheme({
  palette: {
    type: "light",
    // background: { default: darkMode ? "#303030" : "#f0f0f0" },
    background: { default: "#fff" },
    // primary: { main: darkMode ? deepPurple["A100"] : lightBlue["A700"] },
    primary: { main: "#3c3c3c" },
    // secondary: { main: darkMode ? yellow[700] : deepOrange[500] },
    secondary: { main: deepOrange[500] },
  },
  shape: {
    borderRadius: 8,
  },
  typography: { fontFamily: "Lexend, sans-serif" },
  props: {
    MuiButton: {
      disableElevation: true,
    },
  },
  overrides: {
    MuiDivider: {
      root: {
        margin: "64px 0",
        backgroundColor: lightBlue["A700"],
      },
      vertical: {
        margin: "0 24px",
      },
    },
    MuiTypography: {
      h3: {
        fontWeight: 700,
      },
      h4: {
        fontWeight: 700,
        // textTransform: "uppercase",
      },
    },
  },
});

export default theme;
