import { Button, Snackbar, Typography } from "@material-ui/core";
import { Link } from "gatsby-theme-material-ui";
import ThemeTopLayout from "gatsby-theme-material-ui-top-layout/src/components/top-layout";
import { SnackbarProvider } from "notistack";
import React from "react";
import Helmet from "react-helmet";

import { Footer } from "../../components/footer";
import { Navigation } from "../../components/navigation";
import { getCookie, setCookie } from "../../util";

export default function TopLayout({ children, theme }) {
  const [consented, setConsented] = React.useState(() => {
    const consent = getCookie("pr-cookie-consent") ?? "false";
    return JSON.parse(consent);
  });

  const consentHandler = () => {
    setConsented(true);
    setCookie("pr-cookie-consent", "true", 10 * 365);
  };

  return (
    <ThemeTopLayout theme={theme}>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      />
      <SnackbarProvider>
        <Navigation />
        {children}
        <Footer />
      </SnackbarProvider>

      <Snackbar
        open={!consented}
        autoHideDuration={6000}
        // onClose={consentHandler}
        message={
          <Typography style={{ maxWidth: 630 }}>
            This site uses cookies to deliver our services. By using our site,
            you acknowledge that you have read and understand our{" "}
            <Link to="/terms">Terms of Service and Cookie Policy</Link>. Your
            use of Post Reality's Products and Services, is subject to these
            policies and terms.
          </Typography>
        }
        action={
          <Button
            variant="contained"
            color="secondary"
            onClick={consentHandler}
          >
            Accept
          </Button>
        }
      />
    </ThemeTopLayout>
  );
}
