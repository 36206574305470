import {
  createStyles,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  mdiEmailOutline,
  mdiFacebook,
  mdiInstagram,
  mdiLinkedin,
  mdiMapMarker,
} from "@mdi/js";
import Icon from "@mdi/react";
import { Link } from "gatsby-theme-material-ui";
import React from "react";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      [theme.breakpoints.between(
        theme.breakpoints.values.xs,
        theme.breakpoints.values.lg
      )]: {
        flexDirection: "column",
        "& .MuiDivider-root": {
          margin: theme.spacing(1.5, 0),
        },
      },
      justifyContent: "center",
      alignItems: "center",
      padding: theme.spacing(4, 0),
      width: "100%",
      backgroundColor: "#f0f0f0",
    },
    social: {
      display: "flex",
    },
    icon: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      marginRight: theme.spacing(2),
    },
  })
);

export const Footer: React.FC = () => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      {/* <Typography align="center" paragraph>
        Stay connected to hear about news and updates
      </Typography> */}
      <div className={styles.social}>
        <Link
          className={styles.icon}
          href="https://www.facebook.com/postrealityAR/"
          title="Facebook"
          target="_blank"
          color="textPrimary"
        >
          <Icon path={mdiFacebook} size={1.2} />
        </Link>
        <Link
          className={styles.icon}
          href="https://www.instagram.com/postreality_ar/?hl=en"
          title="Instagram"
          target="_blank"
          color="textPrimary"
        >
          <Icon path={mdiInstagram} size={1.2} />
        </Link>
        <Link
          className={styles.icon}
          href="https://www.linkedin.com/company/54074929"
          title="LinkedIn"
          target="_blank"
          color="textPrimary"
        >
          <Icon path={mdiLinkedin} size={1.2} />
        </Link>
      </div>

      <Divider
        flexItem
        orientation="vertical"
        style={{ backgroundColor: "black" }}
      />

      <Link
        className={styles.icon}
        href="mailto:hello@postreality.io"
        title="Contact Us"
        target="_blank"
        color="textPrimary"
      >
        <Icon path={mdiEmailOutline} size={1.2} />
      </Link>
      <Typography>
        <Link
          href="mailto:hello@spearxr.com"
          title="Contact Email"
          color="textPrimary"
          underline="always"
        >
          hello@spearxr.com
        </Link>
      </Typography>

      <Divider
        flexItem
        orientation="vertical"
        style={{ backgroundColor: "black" }}
      />

      <Link
        className={styles.icon}
        href="https://www.google.com/maps/place/The+Glimpse+Group/@40.75161,-73.9858803,17z/data=!3m2!4b1!5s0x89c259aa19ec6ded:0x5fecbb5b81b5d139!4m5!3m4!1s0x89c258fd2b71dacd:0x993637c4aac5b7ce!8m2!3d40.751606!4d-73.9836916"
        title="LinkedIn"
        target="_blank"
        color="textPrimary"
      >
        <Icon path={mdiMapMarker} size={1.2} />
      </Link>
      <Typography align="center">
        <Link
          href="https://www.google.com/maps/place/The+Glimpse+Group/@40.75161,-73.9858803,17z/data=!3m2!4b1!5s0x89c259aa19ec6ded:0x5fecbb5b81b5d139!4m5!3m4!1s0x89c258fd2b71dacd:0x993637c4aac5b7ce!8m2!3d40.751606!4d-73.9836916"
          title="LinkedIn"
          target="_blank"
          color="textPrimary"
          underline="always"
        >
          15 West 38th Street, 12th Floor, New York, NY 10018
        </Link>
      </Typography>

      <Divider
        flexItem
        orientation="vertical"
        style={{ backgroundColor: "black" }}
      />

      <Typography>
        <Link
          to="/terms"
          title="Contact Email"
          color="textPrimary"
          underline="always"
        >
          Terms of Service
        </Link>
      </Typography>

      <Divider
        flexItem
        orientation="vertical"
        style={{ backgroundColor: "black" }}
      />

      <Typography>SpearXR &copy; {new Date().getFullYear()}</Typography>
    </div>
  );
};
